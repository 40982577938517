<app-breadcrumb [title]="'Login'" [breadcrumb]="'Login'"></app-breadcrumb>
<!-- section start -->
<section class="login-page section-b-space">
    <div class="container">
        <div class="row content-center">
            <div class="col col-12 content-center">
                  <img src="assets/appImages/logoMenu.svg" width="130" height="140" class="img-fluid" alt="logo">
            </div>
            <div class="col-lg-6">
                <div class="theme-card">
                    <form class="theme-form" (ngSubmit)="loginUser()"> <!-- Cambiado aquí -->
                        <div class="form-group">
                            <label for="email">Usuario</label>
                            <input type="text" class="form-control" id="email" placeholder="Escriba su usuario" 
                                [(ngModel)]="registerCredentials.user" name="user" required> <!-- Agregado name="user" -->
                        </div>
                        <div class="form-group">
                            <label for="review">Contraseña</label>
                            <input type="password" class="form-control" id="review" placeholder="Ingrese su contraseña" 
                                [(ngModel)]="registerCredentials.password" name="password" required> <!-- Agregado name="password" -->
                        </div>
                        <div class="btn-centered">
                            <button type="submit" class="btn btn-solid">Login</button> <!-- Cambiado aquí -->
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</section>
<!--Section ends-->