<ng-template class="theme-modal" #fullView let-modal>
    <div class="modal-content quick-view-modal">
        <div class="modal-body">
            <button type="button" class="close float-end border-0" (click)="modal.dismiss('Cross click')">
                <span aria-hidden="true">&times;</span>
            </button>
            <div class="row">
                <div class="col-lg-6  col-xs-12">
                    <owl-carousel-o [options]="ProductDetailsMainSliderConfig" #owlCar  class="product-slick height-512">
                        <ng-container *ngFor="let image of product_images; index as i">
                            <ng-template carouselSlide [id]="i" >
                                <div class="zoom-container" (click)="openPhotoSwipe(i)">
                                    <img [defaultImage]="'assets/images/product/placeholder.jpg'" 
                                       [lazyLoad]="apiService.thumbnailsDirectory + image + (image.includes('.') ? '' : '.jpg')" 
                                       [alt]="product.prod_name" class="img-fluid" [src]="apiService.imageDirectory + image + (image?.includes('.') ? '' : '.jpg')">
                                </div>
                            </ng-template>
                        </ng-container>
                    </owl-carousel-o>
                    <div class="row" *ngIf="product_images.length > 1">
                        <div class="slider-nav">
                            <owl-carousel-o [options]="ProductDetailsThumbConfig" class="product-slick height-240">
                                <ng-container *ngFor="let image of product_images; index as i">
                                    <ng-template carouselSlide [id]="i">
                                        <div class="owl-thumb" [class.active]="i == activeSlide">
                                            <img [defaultImage]="'assets/images/product/placeholder.jpg'" 
                                            [lazyLoad]="apiService.thumbnailsDirectory + image + (image?.includes('.') ? '' : '.jpg')"
                                            [alt]="product.prod_name"
                                            class="img-fluid"
                                            [src]="apiService.thumbnailsDirectory + image + (image?.includes('.') ? '' : '.jpg')"
                                            (click)="owlCar.to(activeSlide = i.toString())">
                                        </div>
                                    </ng-template>
                                </ng-container>
                            </owl-carousel-o>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 rtl-text">
                    <div class="product-right">
                        <h2>{{ product.prod_name | titlecase }}</h2>
                        <h3>{{ product.price | currency }}
                            <!-- <del *ngIf="product?.discount"><span class="money">{{ product.price * currency?.price | currency:currency?.currency:'symbol' }}</span></del> -->
                        </h3>
                        <!-- <ul class="color-variant" *ngIf="Color(product.variants).length">
                            <li [class]="color" *ngFor="let color of Color(product.variants)"
                                [ngStyle]="{'background-color': color}" (click)="ChangeVariants(color, product)">
                            </li>
                        </ul> -->
                        <div class="border-product">
                            <h6 class="product-title">Detalle del Producto</h6>
                            <p [innerHTML]="product.prod_descriptions.substring(0, 200)+'...'"></p>
                        </div>
                        <div class="product-description border-product">
                            <!-- <div class="size-box" *ngIf="Size(product.variants).length">
                                <ul>
                                    <li *ngFor="let size of Size(product.variants)">
                                        <a href="javascript:void(0)">{{ size | titlecase }}</a>
                                    </li>
                                </ul>
                            </div> -->
                            <h5 class="avalibility" *ngIf="counter <= product.stock && userType == '2'"><span>{{ product.stock }} En Stock</span></h5>
                            <h5 class="avalibility" *ngIf="counter > product.stock && userType == '2'"><span>Solo hay {{ product.stock }} en Stock</span></h5>
                            <h6 class="product-title">Cantidad</h6>
                            <div class="qty-box">
                                <div class="input-group">
                                    <span class="input-group-prepend">
                                        <button type="button" class="btn quantity-left-minus" data-type="minus" (click)="decrement()">
                                            <i class="ti-angle-left"></i>
                                        </button>
                                    </span>
                                    <input type="text" name="quantity" class="form-control input-number"  [value]="counter" disabled>
                                    <span class="input-group-prepend">
                                        <button type="button" class="btn quantity-right-plus" data-type="plus" (click)="increment()">
                                            <i class="ti-angle-right"></i>
                                        </button>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="product-buttons">
                            <a href="javascript:void(0)" class="btn btn-solid" [class.disabled]="counter > product.stock" (click)="addToCart(product)">Agregar al Carrito</a>
                            <a [routerLink]="['/shop/product/no/sidebar/', product.prod_name.replace(' ', '-')]" class="btn btn-solid">Detalle</a>
                        </div>
                    </div>
                </div>
            </div>

            <app-related-product [type]="product?.cat_id"></app-related-product>
        </div>
    </div>
</ng-template>