<div *ngIf="!loader">
  <div class="img-wrapper">
    <div class="lable-block">
      <span class="lable4 orange" *ngIf="product?.status == 1">Destacado</span>
      <span class="lable3" *ngIf="product?.status == 2">Nuevo</span>
      <span class="lable4 red" *ngIf="product?.status == 4">Agotado</span>
      <span class="lable4 green" *ngIf="product?.status == 6">Outlet</span>
    </div>
    <div class="front">
      <a href="javascript:void(0)" (click)="FullView.openModal()">
      <!-- <a href="javascript:void(0)" (click)="openFullScreenPopup(product?.prod_name)"> -->
        <div class="img-fluid lazy-loading"
            style="height: 200px; width: 100%; background-size: contain; background-repeat: no-repeat; background-position: center;" 
            [ngStyle]="{'background-image': 'url(' + (ImageSrc ? ImageSrc : apiService.thumbnailsDirectory + product?.image + (product?.image?.includes('.') ? '' : '.jpg')) + ')'}">
        </div>
      </a>
    </div>
    <div class="card-info-desired" *ngIf="desiredProduct.desiredProductsIds.includes(product.prod_id)">
      <a href="javascript:void(0)" title="Agregar al Carrito" (click)="addToWishlist(product)">
        <i class="fa fa-shopping-cart"></i>
      </a>
    </div>
    <div class="cart-info cart-wrap">
      <a href="javascript:void(0)" title="Agregar al Carrito" (click)="addToWishlist(product)">
        <i class="fa fa-shopping-cart" aria-hidden="true"></i>
      </a>
      <a href="javascript:void(0)" title="Vista Rápida" (click)="QuickView.openModal()">
        <i class="ti-search" aria-hidden="true"></i>
      </a>
    </div>
  </div>
  <div class="product-detail">
    <div class="blue-info-box">
      <bar-rating [rate]="5" [readOnly]="true"></bar-rating>
      <h6>{{ product?.prod_name | titlecase }}</h6> 
      <b *ngIf="userType == '2'">Cantidad:</b><span *ngIf="userType == '2'">{{ product?.stock }} |</span> <b>Precio:</b>{{ product?.price | currency }}<br/>
      <div style="display: flex">
        <span [innerHTML]="product?.prod_descriptions" class="text-truncate"></span>
      </div>
    </div>
  </div>
</div>
<app-skeleton-product-box *ngIf="loader"></app-skeleton-product-box>
<app-quick-view #quickView [product]="product" [currency]="currency"></app-quick-view>
<app-full-view #fullView [product]="product" [currency]="currency"></app-full-view>
<app-cart-modal #cartModal [product]="product" [currency]="currency" *ngIf="cartModal"></app-cart-modal>
<!-- <app-product-no-sidebar #productNoSidebar [product]="product" *ngIf="productNoSidebar"></app-product-no-sidebar> -->

<!-- <div *ngIf="isModalOpen" class="modal full-screen-modal detail-modal">
    <div class="modal-content">
        <span class="close" (click)="isModalOpen = false">&times;</span>
        <iframe [src]="selectedProductUrl" width="100%" height="100%" style="border: none;"></iframe>
    </div>
</div> -->

